import { PropsWithChildren } from "react"
import { createUseStyles } from "react-jss"

export const useStepperWizardStyles = createUseStyles((theme) => ({
  root: {
    width: "100%",
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepContent: {
    paddingTop: 0,
  },
  cancelButtonStyle: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[900],
    border: `1px solid ${theme.palette.grey[800]}`,
    textTransform: "none",
    height: 40,
    paddingLeft: 27,
    paddingRight: 27,
    width: 106,
    fontSize: 16,
    fontWeight: 600,
  },
  active: {
    color: theme.palette.tertiary.main,
    marginRight: 10,
  },
  inactive: {
    color: theme.palette.secondary[600],
    marginRight: 10,
  },
  modalInfo: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  stepperContainer: {
    display: "flex",
    justifyContent: "center",
  },
  topDivider: {
    margin: "16px 0px 14px 0px",
  },
  label: {},
}))

export const useQontoStepIconStyles = createUseStyles((theme) => ({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
  },
  active: {
    color: theme.palette.tertiary.main,
  },

  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: theme.palette.info.main,
    zIndex: 1,
    fontSize: 18,
  },
}))

const useActionBarStyles = createUseStyles((theme) => ({
  actionBar: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
  },
}))

export const DialogActions = ({ children }: PropsWithChildren<{}>) => {
  const classes = useActionBarStyles()
  return <div className={classes.actionBar}>{children}</div>
}
