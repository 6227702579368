export const CUSTOMER_ADMIN_RELAY_NODES = "/resource/api/v1/resources/relay-nodes/"
export const CUSTOMER_ADMIN_RELAY_NODES_EXPANDED_VIEW = (id: string) =>
  `/resource/api/v1/resources/relay-nodes/${id}/expanded-view/`
export const REMOVE_CLOUD_RELAY_NODE = (relayNodeId: string): string =>
  `/resource/api/v1/resources/relay-nodes/${relayNodeId}/`
export const FORCE_REMOVE_RELAY_NODE = (id: string): string =>
  `/resource/api/v1/resources/relay-nodes/hard-delete/${id}/`
export const FORCE_REMOVE_DRAP_LOAD_BALANCER = (id: string): string =>
  `/resource/api/v1/drap/load-balancers/hard-delete/${id}/`

export const RELAY_NODE_NOTIFICATIONS = "/resource/api/v1/release/notifications/relay/"

export const UPGRADE_RELAY_NODES = "/resource/api/v1/resources/relay-nodes/upgrade/"

export const RETRY_RELAY_UPGRADE = "/resource/api/v1/resources/relay-nodes/upgrade/"
export const VERSIONS_CHECK = "/resource/api/v1/release/versions/check/"
export const VERSIONS_ROLLOUT = "/resource/api/v1/release/versions/rollout/"
export const GET_RELAY_NODES_INSTANCE_TYPES = "/resource/api/v1/resources/relay-nodes/instance-types"
export const SUPER_ADMIN_WORKSPACES_LIST_ANALYTICS = "/analytics/api/v1/workspaces/list"
export const GET_TENANTS = "/auth/api/v1/clients"
export const GET_DRAP_NODES = "/resource/api/v1/drap/nodes/"
export const GET_DRAP_REGIONS = "/resource/api/v1/drap/regions/"
export const GET_REGIONS = "/resource/api/v1/resources/relay-nodes/regions/"
export const DRAP_LOAD_BALANCERS = "/resource/api/v1/drap/load-balancers/"
export const EDIT_REMOVE_DRAP_LOAD_BALANCERS = (loadBalancerId: string): string =>
  `/resource/api/v1/drap/load-balancers/${loadBalancerId}/`
export const DRAP_LOAD_BALANCER_TREE_CHART = (loadBalancerId: string): string =>
  `/resource/api/v1/drap/load-balancers/${loadBalancerId}/tree-chart/`
export const GET_SUPER_ADMIN_IDENTITY_PROVIDERS = "/auth/api/v1/accounts/ztna-admin/identity-providers/"

export const UPDATE_SUPER_ADMIN_IDENTITY_PROVIDERS = `/auth/api/v1/accounts/ztna-admin/identity-providers/`
export const FILTER_RELAY_NODES = "/resource/api/v1/resources/relay-nodes/filter-by/"
export const SUPER_ADMIN_WORKSPACES_ANALYTICS = "/analytics/api/v1/workspaces/"

export const SUPER_ADMIN_TENANTS_STATS = "/analytics/api/v1/workspaces/active-count"
export const SUPER_ADMIN_ACTIVE_USERS_STATS = "/analytics/api/v1/users/active-count"
export const SUPER_ADMIN_ACCESS_KEY = "/auth/api/v1/access-keys/"
