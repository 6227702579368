import { EventNames } from "./eventNames"

export enum LogLevels {
  "fatal" = "fatal",
  "error" = "error",
  "warning" = "warning",
  "debug" = "debug",
  "info" = "info",
}

export type EventNameType = keyof typeof EventNames

export interface LoggingFn {
  (eventName: EventNameType, data?: { [s: string]: any } | Error, tags?: { [s: string]: string }): void
}

type VisitorMetaData = {
  id: string
  full_name: string
  email: string
  role: string
}

type AccountMetaData = {
  id: string
  city: string
  country: string
  cuid: string
  name: string
  license: string
}

export type LoggerInitializePayload = {
  visitor: VisitorMetaData
  account: AccountMetaData
}

export type LoggerInitializeFn = (payload?: LoggerInitializePayload) => void

export interface Logger {
  initialize: LoggerInitializeFn
  flush: () => void
  fatal: LoggingFn
  error: LoggingFn
  warning: LoggingFn
  info: LoggingFn
  debug: LoggingFn
}
