import { createUseStyles } from "react-jss"
import { TABS_BANNER_HEIGHT } from "src/constants/layout"
import {
  APP_LAYOUT_GRID_ROW_GAP,
  APP_MARGIN,
  CLOSED_DRAWER_WIDTH,
  DRAWER_WIDTH,
  TOP_NAV_HEIGHT,
} from "src/utils/constants"

type Props = {
  isSideBarOpened: boolean
  tabsBannerHeight: number
  upgradeBannerHeight: number
}

export const useAppContainerStyles = createUseStyles<string, Props>((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: ({ isSideBarOpened }) => (isSideBarOpened ? `${DRAWER_WIDTH}px auto` : `0px 1fr`),
    gridTemplateRows: ({ isSideBarOpened }) =>
      isSideBarOpened ? `${TOP_NAV_HEIGHT}px 52px auto` : `${TOP_NAV_HEIGHT}px 40px auto`,
    gridTemplateAreas: ({ isSideBarOpened }) =>
      isSideBarOpened
        ? `"TopNav TopNav""SideNav Content""SideNav Content"`
        : `"TopNav TopNav""SideNav Content""SideNav Content"`,
    rowGap: `${APP_LAYOUT_GRID_ROW_GAP}px`,
    columnGap: ({ isSideBarOpened }) => (isSideBarOpened ? theme.spacing(4) : 0),
    height: "100%",
    margin: `${APP_MARGIN}px`,
  },
  container: {
    padding: 0,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 6,
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.grey[700],
    },
  },
  containerSpacing: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  endUserContainerSpacing: {
    marginLeft: ({ isSideBarOpened }) => (isSideBarOpened ? 0 : `${CLOSED_DRAWER_WIDTH + 30}px`),
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    containerSpacing: { paddingRight: 16, paddingLeft: 16 },
    root: { minWidth: 1280 },
  },
  content: {
    // minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: `calc(100vh - ${TOP_NAV_HEIGHT}px - ${APP_MARGIN * 2}px - ${APP_LAYOUT_GRID_ROW_GAP}px)`,
    overflow: "auto",
    backgroundColor: theme.palette.background.surfaceElevation0,
    gridArea: "Content",
  },
  bgGrey: {
    backgroundColor: "#F9F9F9",
  },
  pageHeaderMargin: {
    marginLeft: ({ isSideBarOpened }) => (isSideBarOpened ? `0px` : `${CLOSED_DRAWER_WIDTH + 20}px`),
  },
  navMarginSidebarOpen: {
    marginLeft: 0,
  },
  tabsContainer: {
    backgroundColor: "#E4E8F2",
    color: theme.palette.grey[50],
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: theme.palette.grey[900],
      backgroundColor: theme.palette.grey[500],
      fontWeight: 600,
    },
    "& .MuiTabs-flexContainer": {
      gap: 0,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.primary[600],
    },
    height: TABS_BANNER_HEIGHT,
  },
  tabPanel: {
    marginTop: 25,
    marginLeft: 20,
    marginRight: 20,
  },
  backdropContainer: {
    position: "fixed",
    zIndex: "calc(var(--en-z-index-top) + 2)",
  },
  breadcrumbContainer: {
    marginBottom: theme.spacing(1.5),
  },
  loader: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    justifySelf: "center",
  },
}))
