import { createUseStyles } from "react-jss"

export const useActivityLogsTimeFilterStyles = createUseStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "25px 16px",
    gap: 20,
  },
  select: {
    width: 150,
    "& .react-select__indicator-separator": {
      display: "none",
    },
    "& .react-select__control": {
      borderRadius: 2,
      height: 40,
    },
    "& .react-select__menu-portal": {
      zIndex: 3,
      position: "sticky",
    },
  },
}))
