import { DialogActions, Divider, Typography } from "@material-ui/core"
import clsx from "clsx"
import { Controller, useFormContext } from "react-hook-form"
import { SimpleInput, ZtnaSelect } from "src/shared/components/FormComponents"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { useIpsecConfigurationsStyles } from "../IpsecConfigurations.styles"

interface IpsecPolicyProps {
  setActiveStep: (val: number) => void
  onCloseModal: () => void
}

const IpsecPolicy = ({ setActiveStep, onCloseModal }: IpsecPolicyProps) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const classes = useIpsecConfigurationsStyles()

  const ipsecEncryptionAlgoOptions = [{ label: "aes256-sha256-modp2048", value: "aes256-sha256-modp2048" }]
  const ipsecAuthAlgoOptions = [{ label: "aes256-sha256-modp2048", value: "aes256-sha256-modp2048" }]
  const ipsecDhGroupOptions = [{ label: "14", value: "14" }]

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.selectContainer}>
          <Typography variant="subtitle1" className={classes.labelClass}>
            Encryption Algorithm
          </Typography>
          <div className={clsx(classes.select, { [classes.disabledSelect]: true })}>
            <Controller
              control={control}
              name="ipsecEncryptionAlgo"
              render={({ field: { onChange, value } }) => (
                <ZtnaSelect value={value} options={ipsecEncryptionAlgoOptions} onChange={onChange} disabled />
              )}
            />
          </div>
        </div>

        <div className={classes.selectContainer}>
          <Typography variant="subtitle1" className={classes.labelClass}>
            Authentication Algorithm
          </Typography>
          <div className={clsx(classes.select, { [classes.disabledSelect]: true })}>
            <Controller
              control={control}
              name="ipsecAuthAlgo"
              render={({ field: { onChange, value } }) => (
                <ZtnaSelect value={value} options={ipsecAuthAlgoOptions} onChange={onChange} disabled />
              )}
            />
          </div>
        </div>

        <div className={classes.selectContainer}>
          <Typography variant="subtitle1" className={classes.labelClass}>
            DH Group
          </Typography>
          <div className={clsx(classes.select, { [classes.disabledSelect]: true })}>
            <Controller
              control={control}
              name="ipsecDhGroup"
              render={({ field: { onChange, value } }) => (
                <ZtnaSelect value={value} options={ipsecDhGroupOptions} onChange={onChange} disabled />
              )}
            />
          </div>
        </div>

        <div className={classes.inputField}>
          <SimpleInput
            name="ipsecLifeTime"
            control={control}
            label="LifeTime"
            labelClass={classes.labelClass}
            placeholder="Enter lifeTime"
            error={errors.ipsecLifeTime}
            units="Seconds"
            disable
          />
        </div>

        <div className={classes.inputField}>
          <SimpleInput
            name="ipsecAllowedBandwidth"
            control={control}
            label="Allowed Bandwidth"
            labelClass={classes.labelClass}
            placeholder="Enter allowed bandwidth"
            error={errors.ipsecAllowedBandwidth}
            units="KBs"
            disable
          />
        </div>
      </div>

      <Divider />

      <DialogActions className={classes.stepFooter}>
        <ZtnaButton buttonType="secondary" title="Back" onClick={() => setActiveStep(0)} />
        <ZtnaButton buttonType="primary" title="Confirm" onClick={onCloseModal} />
      </DialogActions>
    </>
  )
}

export default IpsecPolicy
