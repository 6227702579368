import { Typography } from "@material-ui/core"
import { Rating } from "@material-ui/lab"
import { useController } from "react-hook-form"
import { useFormComponentsStyles } from "../FormComponents.styles"
import { StarRatingInputType } from "../FormComponents.types"

const StarRatingInput = ({ control, name, errorText, label, isOptional }: StarRatingInputType): JSX.Element => {
  const classes = useFormComponentsStyles()
  const {
    field: { value, ...inputProps },
  } = useController({
    name,
    control,
  })

  return (
    <div className={classes.outlinedStyled}>
      {label && (
        <Typography variant="subtitle1" className={classes.simpleInputLabel}>
          <b>{label}</b>
          {isOptional && <span className={classes.optional}>Optional</span>}
        </Typography>
      )}
      <Rating value={Number(value)} {...inputProps} precision={0.5} size="large" />
      {errorText && (
        <Typography className={classes.errorMessage} variant="subtitle1">
          {errorText}
        </Typography>
      )}
    </div>
  )
}

export default StarRatingInput
