import { Divider, Drawer, Link, Typography } from "@material-ui/core"
import React from "react"
import { useDispatch } from "react-redux"
import { XIQ_DOMAIN } from "src/constants"
import { PrivateRoutesMap } from "src/routes/config"
import useCatalogApps from "src/services/api/swrHooks/useCatalogApps"
import ErrorSlate from "src/shared/components/ErrorSlate"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import { closeDrawer } from "src/store/ui"
import { useDiscoveryAppsDrawerStyles } from "./DiscoveryAppsDrawer.styles"
import DiscoveryAppsList from "./DiscoveryAppsList"

const DiscoveryAppsDrawer: React.FC = () => {
  const classes = useDiscoveryAppsDrawerStyles()

  const dispatch = useDispatch()
  const onClose = () => dispatch(closeDrawer())

  const { data, error, isLoading } = useCatalogApps()
  const myApps = data.filter((app) => app.appLicense)
  const otherApps = data.filter((app) => !app.appLicense)

  return (
    <Drawer anchor="right" open onClose={onClose} className={classes.drawer}>
      <Typography className={classes.title}>Discovery Apps</Typography>
      <Divider className={classes.divider} />

      {isLoading ? (
        <div className={classes.loaderErrorWrapper}>
          <img src="/loading.gif" alt="Loader" />
        </div>
      ) : error ? (
        <div className={classes.loaderErrorWrapper}>
          <ErrorSlate iconHeight="60px" iconWidth="60px" />
        </div>
      ) : (
        <>
          <Typography className={classes.appsTitle}>
            My Apps
            <Link
              href={`${window.location.protocol}${XIQ_DOMAIN}${PrivateRoutesMap.DISCOVERY_APPS.absolutePath}`}
              target="_blank"
              className={classes.navIconButton}
            >
              <ZtnaIcon name="link" color="#007E91" />
            </Link>
          </Typography>

          <DiscoveryAppsList apps={myApps} />

          <Divider className={classes.divider} />

          <Typography className={classes.appsTitle}>Other Apps</Typography>

          <DiscoveryAppsList apps={otherApps} />
        </>
      )}
    </Drawer>
  )
}

export default DiscoveryAppsDrawer
