import { createUseStyles } from "react-jss"
export const useFormComponentsStyles = createUseStyles((theme) => ({
  input: {
    height: 40,
    width: "100%",
    borderRadius: 4,
    borderColor: theme.palette.secondary[600],
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    padding: 0,
    "& .MuiSelect-root": {
      padding: "10px 32px 10px 14px",
      borderRadius: 4,
      fontSize: 14,
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 14,
    },
  },
  caretIconClose: {
    marginTop: -4,
  },
  caretIconOpen: {
    marginBottom: -4,
  },
  disabledInput: {
    background: `${theme.palette.grey[100]} !important`,
  },
  errorText: {
    display: "flex",
    alignItems: "center",
    gap: 3,
  },
  dangerIcon: {
    alignSelf: "baseline",
    marginTop: 3,
  },
  simpleInputLabel: {
    marginBottom: 3,
    display: "flex",
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey[50],
    alignItems: "center",
  },
  outlinedStyled: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.secondary[600],
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary[600],
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "#ebebeb",
      color: theme.palette.grey[800],
    },
  },
  errorOutlinedStyled: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-root.Mui-hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "& .MuiInputBase-root.Mui-disabled": {
      backgroundColor: "#ebebeb",
      color: theme.palette.grey[800],
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0,
    },
  },
  innerInput: {
    border: 2,
  },
  passwordIcon: {
    display: "flex",
    fontSize: "1rem",
    padding: "6px 12px 6px 12px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    backgroundColor: theme.palette.error.main,
    "& .MuiInputAdornment-root": {
      maxHeight: 50,
      height: 39,
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
    },
  },
  errorIcon: {
    fontSize: "1rem",
    padding: "6px 12px 6px 12px",
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    border: "1px solid #c8c8c8",
    boxShadow: "0 0 15px 0 rgba(0, 0, 0, 0.16)",
  },
  errorTooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 12,
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 8,
    paddingBottom: 8,
  },
  errorTooltipHidden: {
    display: "none",
  },
  errorArrow: {
    color: theme.palette.primary.main,
  },
  arrow: {
    fontSize: 18,
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #c8c8c8",
    },
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: 13,
  },
  select: {
    minHeight: 38,

    "& .react-select__control": {
      overflow: "hidden !important",
      borderRadius: 4,
    },
    "& .react-select__placeholder": {
      color: "#a4a4a4",
      fontSize: 14,
    },
    "& .react-select__indicator-separator": {
      display: "none",
    },
    "& .react-select__single-value": {
      width: "95%",
    },

    "& .react-select__multi-value": {
      backgroundColor: "#f1f1f1",
    },
    "& .react-select__multi-value__label": {
      color: theme.palette.secondary[600],
      fontSize: 14,
      height: 25,
    },
    "& .react-select__multi-value__remove": {
      color: theme.palette.secondary[600],
      fontSize: 14,
    },
  },
  errorSelect: {
    "& .react-select__indicator": {
      background: theme.palette.error.main,
      marginLeft: 3,
    },
    "& .react-select__indicators": {
      width: 38,
      background: theme.palette.error.main,
    },
    "& .react-select__control": {
      border: `1px solid ${theme.palette.error.main}`,
      minHeight: 38,
      overflow: "hidden",
    },
  },
  tableSelect: {
    minHeight: 40,
    "& .react-select__indicator-separator": {
      display: "none",
    },
    "& .react-select__placeholder": {
      fontSize: 14,
    },
    "& .react-select__loading-indicator": {
      fontSize: 2,
    },
  },
  tableSelectWrapper: {
    fontSize: 14,
    padding: "10px 10px 10px 0px",
  },
  optional: {
    fontStyle: "italic",
    marginLeft: 7,
    color: theme.palette.grey[800],
  },
  popOverOpen: {
    transform: "rotate(180deg)",
  },
  inputFieldInfoIcon: {
    display: "flex",
    marginLeft: 8,
  },
  inputFieldInfoIconTooltip: {
    top: 2,
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
  },
  inputFieldInfoIconTooltipArrow: {
    color: theme.palette.primary.main,
  },
  inputUnits: {
    display: "flex",
    paddingRight: 12,
    color: theme.palette.tertiary.main,
  },
  disabledInputUnits: {
    color: theme.palette.secondary[600],
  },
  unitWithError: {
    display: "flex",
    alignItems: "center",
  },
  // For ZTNA Chip Select Dropdown
  chipsContainer: {
    marginTop: 8,
    display: "flex",
    gap: 5,
    flexWrap: "wrap",
    alignItems: "center",
  },
  chip: {
    cursor: "pointer",
    backgroundColor: `rgba(0,0,0,0)`,
    color: theme.palette.border.default,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.border.default,
    "& svg": {
      marginRight: 8,
      color: theme.palette.border.default,
    },
    display: "flex",
    borderRadius: 50,
    gap: 7,
    padding: 2,
    paddingLeft: 10,
    fontSize: 14,
    fontWeight: 500,
  },
  buttonText: {
    fontSize: 16,
    color: theme.palette.primary[600],
    fontWeight: 600,
    cursor: "pointer",
  },
  creationButton: {
    width: "100%",
    minWidth: "100%",
    backgroundColor: theme.palette.grey[100],
    marginTop: 0,
    boxShadow: "none",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: "none",
    color: theme.palette.primary[600],
    fontSize: 16,
    textAlign: "left",
    fontWeight: 600,
    padding: 0,
    cursor: "pointer",
  },
  creationButtonMultiSelect: {
    backgroundColor: theme.palette.grey[500],
  },
  prePostIcon: {
    display: "flex",
  },
  fullWidth: {
    flex: 1,
  },
  multiselectOptionsContainer: {
    color: theme.palette.content.default,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    padding: "8px 16px",
  },
  labelWithSyncContainer: {
    display: "flex",
    alignItems: "center",
    gap: 7,
  },
  addBtn: {
    color: theme.palette.content.accentDefault,
  },
  chipContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    gap: 5,
    cursor: "auto",
  },
  listItemContainer: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    gap: 10,
    width: "100%",
  },
  countryName: {
    marginRight: 10,
  },
}))
