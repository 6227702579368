import { createUseStyles } from "react-jss"

export const useRowCellRendererStyles = createUseStyles((theme) => ({
  rowCell: {
    color: theme.palette.grey[50],
    fontSize: 16,
    maxWidth: 400,
  },
  overflowEllipses: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  noPaddingRowCell: {
    padding: 0,
  },
  badgeWrapper: {
    display: "flex",
    alignItems: "center",
  },
  badgeWrapperWithIcon: {
    marginLeft: 28,
  },
  clickableBadgeIcon: {
    padding: 9,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  defaultCursor: {
    cursor: "default",
  },
  linkText: {
    fontWeight: 600,
    color: theme.palette.primary[600],
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  wrapText: {
    overflow: "hidden",
    lineHeight: "1.5em",
    maxHeight: "3em",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    maxWidth: 400,
  },
  multilineText: {
    display: "flex",
    flexDirection: "column",
  },
  buttonText: {
    color: theme.palette.primary[600],
    cursor: "pointer",
  },
  badgeIcon: {
    display: "flex",
    marginLeft: 12,
    alignItems: "center",
    width: 15,
    height: 15,
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  badge: {
    height: "28px !important",
    borderRadius: "13px !important",
    minWidth: 124,
    "& span": {
      fontSize: 14,
      fontWeight: 600,
    },
    color: `${theme.palette.grey[50]} !important`,
    backgroundColor: `${theme.palette.grey[50]}1a !important`,
  },
  badgeSuccess: {
    color: "#008700 !important",
    backgroundColor: "#e5f3e5 !important",
  },
  badgeWarning: {
    color: "#b87a00 !important",
    backgroundColor: "#f8f1e5 !important",
  },
  badgeDanger: {
    color: `${theme.palette.error.main} !important`,
    backgroundColor: "#fde5e5 !important",
  },
  noBadge: {
    backgroundColor: "transparent !important",
  },
  badgeIndeterminate: {
    color: `${theme.palette.grey[50]} !important`,
    backgroundColor: `${theme.palette.grey[50]}1a !important`,
  },
  dataIconCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  leftIconCell: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.content.default,
  },
  leftMarginWithoutIcon: {
    marginLeft: 28.5,
    color: theme.palette.content.default,
  },
  leftMarginWithIcon: { marginLeft: 6.5, color: theme.palette.content.default },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontSize: 14,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
  iconsContainer: {
    display: "flex",
  },
  icon: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  iconArray: {
    display: "flex",
    marginRight: 5,
    marginTop: 2,
  },
  countryBadgeContainer: {
    display: "flex",
    alignItems: "center",
  },
  countryName: {
    paddingLeft: 8.9,
    color: theme.palette.grey[200],
    fontSize: 16,
  },
  text: {
    fontSize: 14,
    color: theme.palette.grey[50],
  },
  iconCellData: {
    maxWidth: 300,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "default",
    marginRight: 6.5,
    color: theme.palette.tertiary.main,
  },
  showTooltipCellData: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "default",
  },
  prePostIconWrapper: {
    width: "100%",
    margin: "0 6px",
    display: "flex",
    alignItems: "center",
  },
  prePostIconChip: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  upDownArrowContainer: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginRight: 3,
      marginLeft: 5,
    },
  },
  divider: {
    backgroundColor: "#d2d2d2",
    height: 22,
    marginLeft: 20,
    marginRight: 20,
  },
  usageAvailabilityChartContainer: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    height: 22,
    marginLeft: 5,
    fontSize: 14,
    fontWeight: 600,
    padding: 0,
    color: theme.palette.primary[600],
    whiteSpace: "nowrap",
    background: "transparent",
    "&:hover": {
      border: 0,
      background: "transparent",
    },
    "&:active": {
      border: 0,
      background: "transparent",
    },
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    "& hr": {
      border: `1px solid ${theme.palette.secondary[400]}`,
      height: 19,
    },
  },
  adSyncedBadge: {
    height: 25,
    borderRadius: 47,
    width: "max-content",
    "& span": {
      fontSize: 14,
      fontWeight: 500,
    },
    backgroundColor: `${theme.palette.grey[50]}1a`,
    color: theme.palette.grey[50],
    marginLeft: 5,
  },
  adSyncedIconWithBadgeContainer: {
    display: "flex",
    alignItems: "center",
  },
  activeSinceText: {
    fontSize: 13,
    fontWeight: 400,
    fontStyle: "italic",
    color: theme.palette.tertiary[600],
    whiteSpace: "nowrap",
    marginLeft: 5,
  },
  breakWord: {
    wordBreak: "break-word",
  },
  chipsText: {
    display: "flex",
    gap: 9,
    "& span": {
      fontSize: 14,
      fontWeight: 500,
      padding: "3px 10px",
      borderRadius: 47,
      backgroundColor: `${theme.palette.grey[50]}1A`,
      color: theme.palette.grey[50],
      textWrap: "nowrap",
    },
  },
  locationIconButton: {
    padding: 0,
    margin: 0,
  },
  cellRightBorder: {
    borderRight: `2px solid ${theme.palette.secondary[400]}`,
  },
  postText: {
    color: theme.palette.content.tertiary,
    marginLeft: 10,
  },
}))
