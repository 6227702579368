import clsx from "clsx"
import { ENList } from "en-react/dist/src/components/List"
import { ENListItem } from "en-react/dist/src/components/ListItem"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { Fragment, useEffect, useState } from "react"
import { RouteType } from "src/routes/config"
import ZtnaIcon from "src/shared/components/Icons"
import { themeData as theme } from "src/theme"
import { NavItem } from "./NavItem"
import { useSideNavStyles } from "./SideNav.styles"

export type CollapsibleNavItemType = {
  route: RouteType
  selectedRoute?: string
  handleNavItemClick: (route: RouteType) => void
  handleNavItemExpanded: (route: RouteType) => void
  expandedNavItemRoute: RouteType | null
}

const isChildSelected = (childs?: RouteType[], selectedRoute?: string) => {
  if (!selectedRoute) return false
  return childs?.filter((childRoute) => {
    const { id } = childRoute
    return id === selectedRoute
  }).length
}

export const CollapsibleNavItem: React.FC<CollapsibleNavItemType> = ({
  route,
  selectedRoute,
  handleNavItemClick,
  handleNavItemExpanded,
  expandedNavItemRoute,
}) => {
  const classes = useSideNavStyles()
  const { title = "", childs, iconName } = route
  const selected = !!isChildSelected(childs, selectedRoute)
  const [isExpanded, setExpanded] = useState(selected)
  const titleForId = title.replaceAll(" ", "-")
  const onItemClick = () => {
    setExpanded(!isExpanded)
    handleNavItemExpanded(route)
  }

  useEffect(() => {
    if (selected) {
      setExpanded(true)
    }
  }, [selected])

  useEffect(() => {
    if (expandedNavItemRoute?.id !== route.id) {
      setExpanded(false)
    }
  }, [expandedNavItemRoute])

  return (
    <Fragment key={`NavCollapseItem-${title}`}>
      <ENListItem
        id={`en-ztna-NavCollapseItem-${titleForId}`}
        onClick={onItemClick}
        isCurrent={false}
        isActive={isExpanded}
      >
        <div className={classes.navItem}>
          {iconName && (
            <ZtnaIcon
              name={iconName}
              color={selected ? theme.palette.background.accentDefault : theme.palette.background.inverseEmphasis}
            />
          )}
          <ENTextPassage>
            <p className={clsx({ [classes.selectedText]: selected }, classes.navItemTitle)}>{title}</p>
          </ENTextPassage>
        </div>

        {childs?.length && (
          <ENList styleModifier={clsx(classes.listRoot, { [classes.expandedBG]: isExpanded })} slot="items">
            <div className={classes.expandedBG}>
              {childs
                ?.filter((route) => route.showInSideNavigation)
                .map((childRoute) => {
                  return (
                    <NavItem
                      key={`ChildNavItem-${childRoute.id}`}
                      route={childRoute}
                      selectedRoute={selectedRoute}
                      isChild
                      handleNavItemClick={handleNavItemClick}
                    />
                  )
                })}
            </div>
          </ENList>
        )}
        {/* <ZtnaIcon name="downArrowFeather" /> */}
      </ENListItem>
      {/* {!isExpanded && <Divider className={classes.listDivider} />} */}
    </Fragment>
  )
}
