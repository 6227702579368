import { IconButton, InputAdornment, OutlinedInput, Tooltip, Typography } from "@material-ui/core"
import clsx from "clsx"
import { useCallback, useState } from "react"
import { useController } from "react-hook-form"
import ZtnaIcon from "src/shared/components/Icons"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { useFormComponentsStyles } from "../FormComponents.styles"
import { SimpleInputType } from "../FormComponents.types"

const TABLET_WIDTH_START = 600

const SimpleInput = ({
  inputId,
  dataTestId,
  control,
  name,
  defaultValue = "",
  style = "",
  label,
  labelClass,
  placeholder,
  isPasswordField,
  startAdornmentIcon,
  hideEndAdornment = false,
  tooltipData,
  tooltipPlacement,
  tooltipClass,
  error,
  tableError,
  multiline,
  rows,
  disable = false,
  autoComplete = "off",
  isOptional,
  isReadOnly,
  iconName,
  iconColor,
  infoIconTooltipText,
  type,
  units,
  autoFocus,
  onKeydown,
}: SimpleInputType): JSX.Element => {
  const classes = useFormComponentsStyles()
  const {
    field: { ref, ...inputProps },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    defaultValue,
  })

  const [isVisible, setVisible] = useState(false)
  const handleClickShowPassword = useCallback((): void => {
    setVisible(!isVisible)
  }, [isVisible])

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
  }

  const startAdornmentRenderer = () =>
    startAdornmentIcon ? (
      <InputAdornment position="start">
        <ZtnaIcon name={startAdornmentIcon} />
      </InputAdornment>
    ) : null

  const endAdornmentRenderer = () => {
    if (isPasswordField && !hideEndAdornment && !error)
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            className={classes.passwordIcon}
            onMouseDown={handleMouseDownPassword}
          >
            {isVisible ? <ZtnaIcon name="eye" /> : <ZtnaIcon name="eyeSlash" />}
          </IconButton>
        </InputAdornment>
      )
    else if (units && !hideEndAdornment && !error)
      return (
        <InputAdornment position="end">
          <span className={clsx(classes.inputUnits, { [classes.disabledInputUnits]: disable })}>{units}</span>
        </InputAdornment>
      )
    else if (units && !hideEndAdornment && error)
      return (
        <div className={classes.unitWithError}>
          <InputAdornment position="end">
            <span className={clsx(classes.inputUnits, { [classes.disabledInputUnits]: disable })}>{units}</span>
          </InputAdornment>
          <Tooltip
            arrow
            placement="top"
            title={
              <div className={classes.errorText}>
                <span className={classes.dangerIcon}>
                  <ZtnaIcon color="#EB0000" height={16} name="error" />
                </span>
                <span>{error ? error.message : tableError}</span>
              </div>
            }
            classes={{ tooltip: classes.errorTooltip, arrow: classes.errorArrow }}
          >
            <div className={classes.error}>
              <InputAdornment position="end">
                <IconButton
                  aria-label="input-error"
                  className={classes.errorIcon}
                  onMouseDown={handleMouseDownPassword}
                >
                  <ZtnaIcon color="#ffffff" height={25} width={20} name="error" />
                </IconButton>
              </InputAdornment>
            </div>
          </Tooltip>
        </div>
      )
    else if (error || tableError || (error && isPasswordField && !hideEndAdornment))
      return (
        <Tooltip
          arrow
          placement="top"
          title={
            <div className={classes.errorText}>
              <span className={classes.dangerIcon}>
                <ZtnaIcon color="#EB0000" height={16} name="error" />
              </span>
              <span>{error ? error.message : tableError}</span>
            </div>
          }
          classes={{ tooltip: classes.errorTooltip, arrow: classes.errorArrow }}
        >
          <div className={classes.error}>
            <InputAdornment position="end">
              <IconButton aria-label="input-error" className={classes.errorIcon} onMouseDown={handleMouseDownPassword}>
                <ZtnaIcon color="#ffffff" height={25} width={20} name="error" />
              </IconButton>
            </InputAdornment>
          </div>
        </Tooltip>
      )
    else return undefined
  }
  return (
    <div
      className={clsx(classes.outlinedStyled, {
        [classes.errorOutlinedStyled]: tableError || error,
      })}
    >
      {label && (
        <Typography variant="subtitle1" className={clsx(classes.simpleInputLabel, labelClass)}>
          {label}
          {isOptional && <span className={classes.optional}>Optional</span>}
          {iconName && (
            <ZtnaTooltip
              arrow
              title={infoIconTooltipText || ""}
              placement="top"
              classes={{ tooltip: classes.inputFieldInfoIconTooltip, arrow: classes.inputFieldInfoIconTooltipArrow }}
            >
              <div className={classes.inputFieldInfoIcon}>
                <ZtnaIcon width="16" height="16" name={iconName} color={disable ? "#b9bec7" : iconColor} />
              </div>
            </ZtnaTooltip>
          )}
        </Typography>
      )}
      <Tooltip
        classes={{
          tooltip: clsx(classes.tooltip, tooltipClass),
          arrow: classes.arrow,
        }}
        interactive
        arrow
        placement={tooltipPlacement || (document.body.clientWidth >= TABLET_WIDTH_START ? "left" : "top")}
        title={tooltipData || ""}
        open={tooltipData !== undefined && invalid}
      >
        <OutlinedInput
          data-testid={dataTestId}
          id={inputId}
          disabled={disable}
          autoComplete={autoComplete}
          inputProps={{
            className: classes.innerInput,
            autoCapitalize: "none",
            autoCorrect: "off",
            autoComplete,
          }}
          className={clsx(classes.input, style, { [classes.disabledInput]: disable })}
          {...inputProps}
          inputRef={ref}
          multiline={multiline}
          rows={rows}
          type={type ? type : isVisible || !isPasswordField ? "text" : "password"}
          placeholder={placeholder}
          startAdornment={startAdornmentRenderer()}
          endAdornment={endAdornmentRenderer()}
          readOnly={isReadOnly}
          autoFocus={autoFocus}
          onKeyDown={onKeydown}
        />
      </Tooltip>
    </div>
  )
}

export default SimpleInput
