import { ENAlert } from "en-react/dist/src/components/Alert"
import { ENIconClose } from "en-react/dist/src/components/Icons/Close"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { ReactNode } from "react"
import { useAlertStyles } from "./Alert.styles"

type Props = {
  variant?: "danger" | "success" | "warning"
  title?: string
  content: string | ReactNode
  handleClose?: () => void
}

const Alert = (props: Props) => {
  const { title, content, variant, handleClose } = props
  const classes = useAlertStyles()

  return (
    <div className={classes.toast}>
      <ENAlert
        isActive
        variant={variant}
        onclose={() => {
          console.log("closed")
        }}
        open={() => {
          console.log("opened")
        }}
        style={{ width: "100%" }}
        styleModifier="en-u-width-100"
      >
        <div className={classes.contentRoot} key=".0">
          {title && title}
          <ENTextPassage slot="header">
            <p className={classes.content}>{content}</p>
          </ENTextPassage>
        </div>
        {handleClose && (
          <div className={classes.closeButton}>
            <ENIconClose size="md" slot="after" onClick={handleClose} />
          </div>
        )}
      </ENAlert>
    </div>
  )
}

export default Alert
