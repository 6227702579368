export const GET_AUTH_TOKEN = `/auth/api/v1/accounts/auth-token/`
export const WORKSPACE_IDP_NAME = "/auth/api/v1/accounts/workspace-idp-name/"
export const CHECK_FOR_VALID_SESSION_END_USER = `/auth/api/v1/accounts/validate/end-user/`

export const END_USER_ANALYTICS_DATA_USAGE = "/analytics/api/v1/services/user/data-usage"
export const END_USER_ANALYTICS_DEVICES_COUNT = "/analytics/api/v1/devices/count/self"
export const END_USER_SERVICE_AVAILABILITY_TREND = "/analytics/api/v1/services/user/service-availability"
export const END_USER_ANALYTICS_LATENCY = "/analytics/api/v1/devices/latency"
export const END_USER_ANALYTICS_DEVICE_AVG_LATENCY = "/analytics/api/v1/devices/avg-latency/self"
export const END_USER_AGENTLESS_PUBLIC_SAAS = "/resource/api/v1/resources/user-agentless-services-public-saas/"
export const END_USER_AGENTLESS_SERVICES = "/resource/api/v1/resources/services/user/agentless/"

export const MICROSOFT_OIDC_AUTH_URL_SIGNUP_INVITE = `/auth/api/v1/accounts/invite/microsoft/signup/`

export const SAML_AUTH_URL_SIGNUP_INVITE = `/auth/api/v1/saml/signup/`

export const MICROSOFT_OIDC_AUTH_URL_LOGIN = `/auth/api/v1/accounts/microsoft/login/`

export const MICROSOFT_OIDC_AUTH_URL_LOGIN_REDIRECT = `/authorize-access?redirect_url=/auth/api/v1/accounts/microsoft/login/`

export const SAML_AUTH_URL_LOGIN = `/auth/api/v1/saml/login/`

export const SAML_AUTH_URL_LOGIN_REDIRECT = `/authorize-access?redirect_url=/auth/api/v1/saml/login/`

export const GOOGLE_AUTH_URL_SIGNUP_INVITE = `/auth/api/v1/accounts/invite/google/signup/`

export const GOOGLE_AUTH_URL_LOGIN_REDIRECT = `/authorize-access?redirect_url=/auth/api/v1/accounts/google/login/`

export const GOOGLE_AUTH_URL_LOGIN = `/auth/api/v1/accounts/google/login/`

export const GET_DOWNLOADS = "/resource/api/v1/downloads/agents/user/"
export const GET_USER_ACTIVE_DEVICES = "/auth/api/v1/accounts/user-active-devices/"

export const FEEDBACK = "/auth/api/v1/feedback/"
export const TOKEN_INFO_URL = "/auth/api/v1/accounts/token-info/"
export const CUSTOMER_ADMIN_PUBLIC_SAAS = "/auth/api/v1/public-saas/"

export const REQUEST_INVITE = "/auth/api/v1/accounts/request-invite/"
