import { SizeType } from "./Modal.types"
// For New EnModal Styles
import { createUseStyles } from "react-jss"

interface DialogueStylePropsType {
  showFooter?: boolean
  size?: SizeType
  width?: string
  disableMinHeight: boolean
}

const mapModalDimension = {
  sm: { width: 460, minHeight: 260, maxHeight: 340, maxContentHeight: 215 },
  md: { width: 750, minHeight: 430, maxHeight: 640, maxContentHeight: 601 },
  lg: { width: 928, minHeight: 650, maxHeight: 740, maxContentHeight: 701 },
  xl: { width: 1168, minHeight: 740, maxHeight: 840, maxContentHeight: 701 },
}

export const useDialogStyles = createUseStyles((theme) => ({
  headerContianer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "13px 24px",
  },
  titleContainer: {
    flex: 1,
  },
  modalCloseIconContainer: {
    cursor: "pointer",
  },
  dialogBox: {
    "& .MuiDialog-paper": {
      width: (props: DialogueStylePropsType) => props.width || mapModalDimension[props?.size || "sm"].width || 448,
      minHeight: (props: DialogueStylePropsType) =>
        !props.disableMinHeight && (mapModalDimension[props?.size || "sm"].minHeight || 260),
      overflowY: "hidden",
      // maxHeight: (props: DialogueStylePropsType) => mapModalDimension[props?.size || "sm"].maxHeight || 340,
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 2400,
    },
    "& .MuiDialogActions-root": {
      height: 70,
    },
  },
  wrapper: {
    position: "relative",
    flexDirection: "column",
    display: "flex",
    flex: 1,
    // maxHeight: (props: DialogueStylePropsType) => mapModalDimension[props?.size || "sm"].maxContentHeight || 210,
    overflowY: "auto",
    "& .MuiDialogContent-root:first-child": {
      overflowX: "hidden",
    },
  },
  cancelBtn: {
    marginRight: 8,
    paddingLeft: 13,
    paddingRight: 13,
    "&.Mui-disabled": {
      color: theme.palette.secondary[600],
    },
  },
  dialogContent: {
    padding: (props: DialogueStylePropsType) => (props.showFooter ? "21px 39px 21px 36px" : 0),
    // maxHeight: (props: DialogueStylePropsType) => mapModalDimension[props?.size || "sm"].maxContentHeight || 210,
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: 5,
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "none",
      boxShadow: "none",
      borderRadius: 4,
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      "-webkit-border-radius": 4,
      borderRadius: 4,
      backgroundColor: "#dedddf",
    },
  },
  hideBorderBottom: {
    "&.MuiDialogContent-dividers": {
      borderBottom: "none",
    },
  },
  dialogHeader: {
    display: "flex",
    alignItems: "center",
    gap: 7,
    maxHeight: 54,
    backgroundColor: theme.palette.grey[100],
  },
  chip: {
    fontSize: 14,
    borderRadius: 2,
    fontWeight: 400,
    color: theme.palette.grey[800],
    backgroundColor: "#f1f1f1",
    height: 29,
    "& .MuiChip-label": { height: 19 },
  },
  infoChip: {
    color: theme.palette.tertiary.main,
    backgroundColor: "#e5f6f9",
  },
}))

// New EnModal Styles
export const useModalStyles = createUseStyles((theme) => ({
  modalContainer: {
    zIndex: theme.zIndex.modal,
  },
  contentWrapper: {
    position: "relative",
    flexDirection: "column",
    display: "flex",
    flex: 1,
  },
}))
