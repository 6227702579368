import { CustomerAdminLayout } from "src/services/api/swrHooks/useAnalyticsLayout"

export const customerAdminLayout: CustomerAdminLayout = {
  connectors: [
    { i: "connectionStatus", x: 0, y: 0, w: 3, h: 2.085 },
    { i: "connectionTrend", x: 3, y: 0, w: 6, h: 2.085 },
    { i: "topConnectorsByUsage", x: 9, y: 0, w: 3, h: 4.17 },
    { i: "connectorPerformanceStats", x: 0, y: 3, w: 3, h: 2.085 },
    { i: "performanceAnomilies", x: 3, y: 3, w: 6, h: 2.085 },
  ],
  policies: [
    { i: "policyActivationStatus", x: 0, y: 0, w: 3, h: 2.085 },
    { i: "timeBasedRules", x: 3, y: 0, w: 6, h: 2.085 },
    { i: "rulesStatus", x: 9, y: 0, w: 3, h: 2.085 },
  ],
  devices: [
    { i: "platformDistribution", x: 0, y: 0, w: 3, h: 2.085 },
    { i: "osDistribution", x: 3, y: 0, w: 6, h: 2.085 },
    { i: "topOSVersions", x: 9, y: 0, w: 3, h: 2.085 },
  ],
  services: [
    { i: "connectionStatus", x: 0, y: 0, w: 3, h: 2.085 },
    { i: "connectionTrend", x: 3, y: 0, w: 6, h: 2.085 },
    { i: "protocolDistribution", x: 9, y: 0, w: 3, h: 2.085 },
    // { i: "topServiceUsage", x: 6, y: 0, w: 3, h: 2.085 },
    // { i: "dataUsageByUsers", x: 9, y: 0, w: 3, h: 2.085 },
    // { i: "serviceUsageTrend", x: 6, y: 3, w: 6, h: 2.085 },
  ],
  tiles: [
    { i: "users", x: 0, y: 0, w: 3, h: 0.74 },
    { i: "devices", x: 3, y: 0, w: 3, h: 0.74 },
    { i: "services", x: 6, y: 0, w: 3, h: 0.74 },
    { i: "connectors", x: 9, y: 0, w: 3, h: 0.74 },
  ],
}

export const ztnaAdminLayout = [
  { i: "activeTenants", x: 0, y: 0, w: 2.4, h: 0.73 },
  { i: "activeUsers", x: 2.4, y: 0, w: 2.4, h: 0.73 },
  { i: "cloudHostedRelays", x: 4.8, y: 0, w: 2.4, h: 0.73 },
  { i: "serviceConnectors", x: 7.2, y: 0, w: 2.4, h: 0.73 },
  { i: "services", x: 9.6, y: 0, w: 2.4, h: 0.73 },
  { i: "allTenants", x: 0, y: 2, w: 12, h: 2.85 },
]

export const endUserLayout = [
  { i: "accessibleServices", x: 0, y: 0, w: 3, h: 0.74 },
  { i: "dataUsage", x: 3, y: 0, w: 3, h: 0.74 },
  { i: "connectedDevices", x: 6, y: 0, w: 3, h: 0.74 },
  { i: "highestLatency", x: 9, y: 0, w: 3, h: 0.74 },
  { i: "dataUsageChart", x: 0, y: 1, w: 6, h: 2.085 },
  { i: "latencyTendChart", x: 6, y: 1, w: 6, h: 2.085 },
  { i: "averagelatencyService", x: 0, y: 1.74, w: 3, h: 2.085 },
  { i: "averagelatencyDevice", x: 3, y: 1.74, w: 3, h: 2.085 },
  { i: "serviceAvailabilityTrend", x: 6, y: 1.74, w: 6, h: 2.085 },
]

export const TOP_NAV_HEIGHT = 65
export const TABS_BANNER_HEIGHT = 53
export const UPGRADE_BANNER_HEIGHT = 52
