import { Paper, Typography } from "@material-ui/core"
import clsx from "clsx"
import { useDispatch } from "react-redux"
import ZtnaIcon, { IconNameType } from "src/shared/components/Icons"
import { openModal } from "src/store/ui"
import { setAllowOpenEncrytionProtocols } from "src/store/ui/uiSlice"
import { TunnelNamesType } from "../EncryptionProtocols"
import { useProtocolCardStyles } from "./ProtocolCard.styles"

interface ProtocolCardProps {
  name: TunnelNamesType
  icon: IconNameType
  isSelected: boolean
  setTunnelType: (val: string) => void
}

const ProtocolCard = ({ name, icon, isSelected, setTunnelType }: ProtocolCardProps) => {
  const classes = useProtocolCardStyles()
  const dispatch = useDispatch()

  return (
    <Paper
      className={clsx(classes.card, { [classes.selectedCard]: isSelected })}
      elevation={0}
      onClick={() => setTunnelType(name)}
    >
      {name === "IPSEC" && (
        <div
          role="button"
          className={classes.settingsIconContainer}
          onClick={() => {
            dispatch(setAllowOpenEncrytionProtocols(true))
            dispatch(openModal("ipsecConfigurations"))
          }}
        >
          <ZtnaIcon name="settings" color="#007E91" width="19" height="21" />
        </div>
      )}

      <ZtnaIcon name={icon} />

      <Typography className={classes.text}>{name}</Typography>

      {name === "IPSEC" && <Typography className={classes.fipsCompliant}>FIPS Compliant</Typography>}
    </Paper>
  )
}

export default ProtocolCard
