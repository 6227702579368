import { IconButton } from "@material-ui/core"
import { ColDef } from "ag-grid-community"
import clsx from "clsx"
import { ENChip } from "en-react/dist/src/components/Chip"
import { RowDataType } from "src/components/CheckListTable"
import ZtnaIcon from "src/shared/components/Icons"
import { useRowCellRendererStyles } from "src/shared/components/Table/RowCellRenderer/RowCellRenderer.styles"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import theme from "src/theme"
import { isInArray } from "src/utils"
import { CellRendererProps } from "../../DataGrid.types"

export type BadgeConfigType = {
  secondaryValues: string[]
  tertiaryValues: string[]
  successValues: string[]
  warningValues: string[]
  dangerValues: string[]
  infoValues: string[]
  primaryValues: string[]
  badgeTooltipKey?: string
  fallbackData?: string
}

const getChipVariant = (value: string, colDef: ColDef) => {
  const {
    secondaryValues = [],
    tertiaryValues = [],
    successValues = [],
    warningValues = [],
    dangerValues = [],
    infoValues = [],
    primaryValues = [],
  } = colDef?.cellRendererParams?.badgeConfig || ({} as BadgeConfigType)

  if (typeof value === "string") {
    if (isInArray(value, secondaryValues)) return "secondary"
    if (isInArray(value, tertiaryValues)) return "tertiary"
    if (isInArray(value, successValues)) return "green"
    if (isInArray(value, warningValues)) return "amber"
    if (isInArray(value, dangerValues)) return "red"
    if (isInArray(value, infoValues)) return "blue"
    if (isInArray(value, primaryValues)) return "purple"
  }
  return "secondary"
}

export const BadgeWithIcons: React.FC<CellRendererProps> = ({ data, colDef }) => {
  const rowData = data as RowDataType
  const cellData = colDef.field && rowData[colDef.field]
  const classes = useRowCellRendererStyles()
  const { cellRendererParams } = colDef
  const { badgeConfig, alignChild } = cellRendererParams
  const {
    badgeTooltipKey = "",
    iconTooltipKey = "",
    icon = "",
    iconColor = "",
    iconConfiguration,
    tooltipPlacement = "bottom",
    badgePaddingLeft = 0,
  } = badgeConfig

  return (
    <div className={classes.badgeWrapper} style={{ justifyContent: alignChild, paddingLeft: badgePaddingLeft }}>
      {badgeTooltipKey && rowData[badgeTooltipKey] ? (
        <>
          <ZtnaTooltip
            title={<div style={{ whiteSpace: "pre-line" }}>{rowData[badgeTooltipKey as string]}</div>}
            placement={tooltipPlacement}
            arrow
            classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          >
            <div>
              <ENChip variant={getChipVariant(cellData, colDef)}>{cellData}</ENChip>
            </div>
          </ZtnaTooltip>

          {isInArray(cellData, iconConfiguration?.showIconValues(rowData) || []) && (
            <ZtnaTooltip title={rowData[iconConfiguration?.iconTooltipKey || ""] || ""} arrow>
              <IconButton
                className={clsx(classes.clickableBadgeIcon, {
                  [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                })}
                onClick={() => iconConfiguration?.onIconClick?.(rowData)}
              >
                <ZtnaIcon name={iconConfiguration?.iconName || "encircledInfo"} color={theme.palette.neutralDark[16]} />
              </IconButton>
            </ZtnaTooltip>
          )}
        </>
      ) : (
        <div>
          <ENChip variant={getChipVariant(cellData, colDef)}>{cellData}</ENChip>
          {isInArray(cellData, iconConfiguration?.showIconValues(rowData) || []) && (
            <ZtnaTooltip title={rowData[iconConfiguration?.iconTooltipKey || ""] || ""} arrow>
              <IconButton
                className={clsx(classes.clickableBadgeIcon, {
                  [classes.defaultCursor]: !iconConfiguration?.onIconClick,
                })}
                onClick={() => iconConfiguration?.onIconClick?.(rowData)}
              >
                <ZtnaIcon name={iconConfiguration?.iconName || "encircledInfo"} color={theme.palette.neutralDark[16]} />
              </IconButton>
            </ZtnaTooltip>
          )}
        </div>
      )}
      {icon && (
        <ZtnaTooltip
          title={rowData[iconTooltipKey] as string}
          placement="bottom"
          arrow
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
        >
          <div data-testid={`${icon}-icon`} className={classes.badgeIcon}>
            {iconTooltipKey && rowData[iconTooltipKey] && (
              <ZtnaIcon name={icon} width="15" height="15" color={iconColor} />
            )}
          </div>
        </ZtnaTooltip>
      )}
    </div>
  )
}

export default BadgeWithIcons
