import { createUseStyles } from "react-jss"

export const useZtnaAsyncMultiSelectStyles = createUseStyles((theme) => ({
  popOverOpen: {
    transform: "rotate(180deg)",
  },
  select: {
    marginTop: 6,
    minHeight: 40,
    position: "relative",
    "& .react-select__control": {
      borderRadius: 2,
      overflowX: "hidden",
      overflowY: "auto",
      maxHeight: 70,
    },
    "& .react-select__placeholder": {
      fontSize: 14,
    },
    "& .react-select__indicator-separator": {
      display: "none",
    },
    "& .react-select__dropdown-indicator": {
      position: "absolute",
      top: 8,
      right: 0,
    },
    "& .react-select__multi-value": {
      backgroundColor: "#f1f1f1",
    },
    "& .react-select__multi-value__label": {
      color: theme.palette.secondary[600],
      fontSize: 14,
    },
    "& .react-select__multi-value__remove": {
      color: theme.palette.secondary[600],
      fontSize: 14,
    },
  },
  errorContainer: {
    marginTop: 10,
  },
}))
