import {
  ColDef,
  FirstDataRenderedEvent,
  GridReadyEvent,
  IsRowSelectable,
  RowModelType,
  SelectionChangedEvent,
  SideBarDef,
  SizeColumnsToContentStrategy,
} from "ag-grid-community"
import "ag-grid-enterprise"
import { AgGridReact } from "ag-grid-react"
import clsx from "clsx"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { debounce } from "lodash"
import { useCallback, useEffect, useMemo, useRef } from "react"
import ActivityLogsTimeFilter from "src/pages/ActivityLogs/ActivityLogsTimeFilter"
import { IconNameType } from "src/shared/components/Icons"
import { PopoverButtonType } from "src/shared/components/Popover"
import { ChartsTimeIntervalFilterType } from "src/utils"
import { useDataGridStyles } from "./DataGrid.styles"
import { BlankSlateProps, DataGridBlankSlate } from "./DataGridBlankSlate"
import { DataGridLoader } from "./DataGridLoader"
import DataGridNavigationBar from "./DataGridNavigationBar/DataGridNavigationBar"

const defaultNoRowsOverlayComponentParams = {
  iconName: "blankSlate" as IconNameType,
  slateHeading: "No Records",
}

type DataGridProps = {
  label?: string
  onSearchCallBack?: (searchText: string) => void
  columnDefs: ColDef[]
  rowData?: any
  onGridReady: (event: GridReadyEvent<any>) => void
  onFirstDataRendered?: (event: FirstDataRenderedEvent<any>) => void
  isExpandable?: boolean
  isSortable?: boolean
  expandableRowComponent?: (params: any) => JSX.Element
  detailRowAutoHeight?: boolean
  suppressContextMenu?: boolean
  isMultiSelect?: boolean
  onRowSelected?: (selectedRows: any) => void
  paginationAutoPageSize?: boolean
  pagination?: boolean
  showLoading?: boolean
  noRowsOverlayComponentParams?: BlankSlateProps
  numberOfRowsSelected?: number
  onActionCallBack?: () => void
  actionText?: string
  cacheBlockSize?: number
  onBulkActionPopover?: {
    popOverList: PopoverButtonType[]
  }
  isRowSelectable?: IsRowSelectable<any>
  isSideBarHidden?: boolean
  containerHeight?: number // For Data Grid inside expanded view
  showNavbars?: boolean
  onRefreshCallBack?: () => void
  onNavigationMenuCallBack?: () => void
  refreshIntervalSeconds?: number
  hasFilters?: boolean
  applyCollapsibleWrapper?: boolean
  hasColumns?: boolean
  dynamicWidthAutoSize?: boolean
  className?: string
  getRowHeight?: (params: any) => number
  handleTimeFilter?: (event: any) => void
  timeFilterValue?: ChartsTimeIntervalFilterType
  setPageSize?: (size: number, pageNumber?: number) => void
  setOffSet?: (offset: number) => void
  rowModelType?: RowModelType
  setAllSelected?: React.Dispatch<React.SetStateAction<boolean>>
  sortingOrder?: ("asc" | "desc" | null)[]
  rowHeight?: number
}

const DataGrid: React.FC<DataGridProps> = ({
  label = "Rows",
  onSearchCallBack,
  detailRowAutoHeight = true,
  suppressContextMenu = true,
  isExpandable = false,
  isSortable = true,
  onGridReady,
  onFirstDataRendered,
  columnDefs,
  rowData,
  expandableRowComponent,
  isMultiSelect = false,
  onRowSelected,
  paginationAutoPageSize,
  pagination = false,
  showLoading,
  noRowsOverlayComponentParams = defaultNoRowsOverlayComponentParams,
  numberOfRowsSelected,
  onActionCallBack,
  actionText = "",
  onBulkActionPopover,
  isRowSelectable,
  isSideBarHidden = false,
  containerHeight, // For Data Grid inside expanded view
  showNavbars = false,
  onRefreshCallBack,
  refreshIntervalSeconds = 30,
  hasFilters = true,
  applyCollapsibleWrapper = false,
  hasColumns = false,
  dynamicWidthAutoSize = false,
  className = "",
  getRowHeight,
  handleTimeFilter,
  timeFilterValue,
  setPageSize,
  setOffSet,
  rowModelType = "clientSide",
  setAllSelected,
  sortingOrder = ["asc", "desc", null],
  cacheBlockSize = 10,
  rowHeight,
}) => {
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      sortable: isSortable,
      resizable: false,
      suppressMovable: true,
      suppressMenu: true,
      cellClass: "ag-text-cell",
    }
  }, [isSortable])

  const gridRef = useRef<AgGridReact>(null)
  const classes = useDataGridStyles()

  const onSelectionChanged = useCallback((e: SelectionChangedEvent<any>) => {
    if (setAllSelected) {
      if (e.source === "uiSelectAll") {
        setAllSelected?.((prev) => {
          if (prev === false) {
            gridRef.current!.api.forEachNode((node) => node?.setSelected(true))
            onRowSelected?.(gridRef.current!.api.getSelectedRows())
            return !prev
          } else {
            gridRef.current!.api.forEachNode((node) => node?.setSelected(false))
            onRowSelected?.(gridRef.current!.api.getSelectedRows())
            return !prev
          }
        })
      } else {
        if (e.source === "checkboxSelected") {
          setAllSelected?.((prev) => {
            if (prev) return !prev
            else return prev
          })
        }
        const selectedRows = gridRef.current!.api.getSelectedRows()
        return onRowSelected?.(selectedRows)
      }
    } else {
      const selectedRows = gridRef.current!.api.getSelectedRows()
      return onRowSelected?.(selectedRows)
    }
  }, [])

  useEffect(() => {
    if (showLoading) {
      setTimeout(() => {
        gridRef?.current?.api?.showLoadingOverlay()
      }, 0)
    } else if (rowData && rowData?.length < 1) {
      setTimeout(() => {
        gridRef?.current?.api?.showNoRowsOverlay()
      }, 0)
    } else {
      setTimeout(() => {
        gridRef?.current?.api?.hideOverlay()
      }, 0)
    }
  }, [showLoading, rowData])

  const sideBar = useMemo<SideBarDef | string | string[] | boolean | null>(() => {
    return {
      toolPanels: [
        ...(hasColumns
          ? [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressRowGroups: true,
                  suppressValues: true,
                  suppressPivots: true,
                  suppressPivotMode: true,
                  suppressColumnFilter: true,
                  suppressColumnSelectAll: true,
                  suppressColumnExpandAll: true,
                },
              },
            ]
          : []),
        ...(hasFilters
          ? [
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
                toolPanelParams: {
                  suppressExpandAll: false,
                  suppressFilterSearch: true,
                },
              },
            ]
          : []),
      ],
      defaultToolPanel: undefined,
      hiddenByDefault: isSideBarHidden,
    }
  }, [isSideBarHidden])

  const autoSizeStrategy = useMemo<SizeColumnsToContentStrategy>(() => {
    return {
      type: "fitCellContents",
    }
  }, [])

  const debouncedSearchHandler = onSearchCallBack && debounce(onSearchCallBack, 500)

  return (
    <div className={clsx(classes.root, className)}>
      <>
        {showNavbars && (
          <DataGridNavigationBar
            onSearchCallBack={onSearchCallBack}
            debouncedSearchHandler={debouncedSearchHandler}
            numberOfRowsSelected={numberOfRowsSelected}
            label={label}
            onActionCallBack={onActionCallBack}
            actionText={actionText}
            onBulkActionPopover={onBulkActionPopover}
            onRefreshCallBack={onRefreshCallBack}
            refreshIntervalSeconds={refreshIntervalSeconds}
          />
        )}
      </>
      {handleTimeFilter && timeFilterValue && (
        <>
          <ENDivider />
          <div className={classes.timefilterWrapper}>
            <ActivityLogsTimeFilter onChange={handleTimeFilter} value={timeFilterValue} />
          </div>
        </>
      )}
      <div className={classes.agGridContainer} style={containerHeight ? { height: containerHeight } : {}}>
        <AgGridReact
          getRowId={(params) => params.data.id} // Note: Always make sure that there is `id` as unique identifier in each row object
          defaultColDef={defaultColDef}
          ref={gridRef}
          className="ag-theme-alpine-dark"
          rowData={rowData}
          sortingOrder={sortingOrder}
          accentedSort={true}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          onFirstDataRendered={onFirstDataRendered}
          masterDetail={isExpandable}
          detailCellRenderer={(params: any) => (
            <div className={clsx({ [classes.expandableComponentWrapper]: applyCollapsibleWrapper })}>
              {expandableRowComponent?.(params)}
            </div>
          )}
          detailRowAutoHeight={detailRowAutoHeight}
          suppressContextMenu={suppressContextMenu}
          rowSelection={isMultiSelect ? "multiple" : "single"}
          onSelectionChanged={onSelectionChanged}
          paginationAutoPageSize={paginationAutoPageSize}
          pagination={pagination}
          suppressRowTransform={true}
          sideBar={sideBar}
          paginationPageSize={10}
          paginationPageSizeSelector={[10, 20, 50]}
          tooltipShowDelay={0}
          loadingOverlayComponent={DataGridLoader}
          noRowsOverlayComponent={DataGridBlankSlate}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          suppressRowClickSelection
          isRowSelectable={isRowSelectable}
          suppressCellFocus
          autoSizeStrategy={dynamicWidthAutoSize ? autoSizeStrategy : undefined}
          getRowHeight={getRowHeight}
          rowModelType={rowModelType}
          cacheBlockSize={cacheBlockSize}
          // enableCellTextSelection
          rowHeight={rowHeight}
          onPaginationChanged={(params: any) => {
            setOffSet?.(params?.api?.paginationGetCurrentPage() * params?.api?.paginationGetPageSize())
            setPageSize?.(params?.api?.paginationGetPageSize(), params?.api?.paginationGetCurrentPage())
          }}
        />
      </div>
    </div>
  )
}

export default DataGrid
