import { Divider, SnackbarContent, SnackbarContentProps } from "@material-ui/core"
import clsx from "clsx"
import { Fragment } from "react"
import ZtnaButton from "src/shared/components/ZtnaButton/ZtnaButton"
import { useBannerStyles } from "./Banner.styles"

interface ButtonType {
  title: string
  handleClick: () => void
}

interface BannerProps {
  message: SnackbarContentProps["message"]
  backgroundColor: string
  textColor?: string
  buttonColor?: string
  buttons: ButtonType[]
  dividerColor?: string
}

const Banner = ({ message, backgroundColor, textColor, buttonColor, buttons, dividerColor }: BannerProps) => {
  const classes = useBannerStyles({ backgroundColor, textColor, buttonColor, dividerColor })

  return (
    <SnackbarContent
      message={message}
      action={buttons.map((button, idx) => {
        const { title, handleClick } = button
        return (
          <Fragment key={`Bannerbutton-${title}`}>
            <ZtnaButton
              variant="text"
              title={title}
              buttonType="primary"
              onClick={handleClick}
              className={clsx(classes.button, idx !== buttons.length - 1 && classes.marginRight)}
            />

            {idx !== buttons.length - 1 && <Divider orientation="vertical" className={classes.divider} />}
          </Fragment>
        )
      })}
      className={classes.root}
    />
  )
}

export default Banner
