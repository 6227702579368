import { ENHeading } from "en-react/dist/src/components/Heading"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import React, { useMemo } from "react"
import CircularLoader from "src/shared/components/CicularLoader/CircularLoader"
import { IconNameType } from "src/shared/components/Icons"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import ZtnaButton from "src/shared/components/ZtnaButton"
import { getXiqUrl } from "src/utils"
import { useAuthorizationMessageStyle } from "./AuthorizationMessage.style"
export type AuthMessageProps = {
  state?: "loading" | "error" | "verifyingLicense" | "invalidLicense" | "licenseVerified"
  messageDescription?: string
}

type AuthorizationMessageType = {
  image: string
  heading?: string | React.ReactNode
  description: string
  headingIcon?: IconNameType
}

const AuthorizationMessage: React.FC<AuthMessageProps> = ({ state, messageDescription }) => {
  const classes = useAuthorizationMessageStyle()

  const messageInfo = useMemo<AuthorizationMessageType | undefined>(() => {
    if (state === "loading")
      return {
        image: `loading.gif`,
        heading: " ",
        description: `Verifying your Universal ZTNA license...`,
      }
    else if (state === "error")
      return {
        image: `ExtremeCloud-IQ.png`,
        heading: "Invalid License",
        description: "Your license for ExtremeCloud Universal ZTNA is invalid.",
        headingIcon: "invalidCircleIcon",
      }
    else if (state === "verifyingLicense")
      return {
        image: `loading.gif`,
        heading: " ",
        description: "Verifying your Universal ZTNA license...",
      }
    else if (state === "licenseVerified")
      return {
        image: `loading.gif`,
        heading: "License Verified",
        description: "Creating Universal ZTNA instance...",
        headingIcon: "doneIcon",
      }
    else if (state === "invalidLicense")
      return {
        image: `sessionExpire.svg`,
        heading: "License Invalid",
        description: "Your license for UZTNA is invalid. You may go back to ExtremeCloud XIQ home page.",
      }
  }, [state])

  return state ? (
    <>
      {state === "error" || state === "invalidLicense" ? (
        <img src={`${process.env.PUBLIC_URL}/${messageInfo?.image}`} className={classes.groupImage} />
      ) : (
        <CircularLoader size="xl" />
      )}
      {messageInfo?.heading && (
        <div className={classes.heading}>
          {messageInfo?.headingIcon && <ZtnaIcon name={messageInfo?.headingIcon} />}
          <ENHeading variant="md">{messageInfo?.heading}</ENHeading>
        </div>
      )}
      <div className={classes.description}>
        <ENTextPassage>
          <p>{messageInfo?.description}</p>
        </ENTextPassage>
      </div>

      {(state === "error" || state === "invalidLicense") && (
        <div className={classes.buttonContainer}>
          <ZtnaButton
            buttonType="primary"
            title={"Back to ExtremeCloud XIQ"}
            onClick={() => (window.location.href = getXiqUrl(window.location.origin))}
          />
        </div>
      )}
    </>
  ) : null
}

export default AuthorizationMessage
