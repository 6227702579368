import { GridApi, IRowNode } from "ag-grid-community"
import { ENIconRefresh } from "en-react/dist/src/components/Icons/Refresh"
import { ENTextField } from "en-react/dist/src/components/TextField"
import { ENTextPassage } from "en-react/dist/src/components/TextPassage"
import { PopoverButtonType } from "src/shared/components/Popover"
import ZtnaButton from "src/shared/components/ZtnaButton"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { themeData as theme } from "src/theme"
import DataGridPopover from "../DataGridPopOver"
import { useDataGridStyles } from "./DataGridNavigationBar.style"

type DataGridNavigationBarProps = {
  onSearchCallBack?: (searchText: string) => void
  debouncedSearchHandler?: (value: string) => void
  numberOfRowsSelected?: number
  label: string
  onActionCallBack?: () => void
  actionText: string
  onRefreshCallBack?: () => void
  onBulkActionPopover?: {
    popOverList: PopoverButtonType[]
  }
  refreshIntervalSeconds?: number
}

const DataGridNavigationBar: React.FC<DataGridNavigationBarProps> = ({
  onSearchCallBack,
  debouncedSearchHandler,
  numberOfRowsSelected,
  label,
  onActionCallBack,
  actionText,
  onRefreshCallBack,
  onBulkActionPopover,
  refreshIntervalSeconds,
}) => {
  const classes = useDataGridStyles()
  const showActionBar = onActionCallBack && !!numberOfRowsSelected
  const showBulkActionMenu = onBulkActionPopover && !!numberOfRowsSelected
  const popOverParams = {
    value: "",
    valueFormatted: "",
    data: "",
    node: {} as IRowNode<string>,
    headerName: "",
    maxWidth: 50,
    rowIndex: 0,
    eGridCell: {} as HTMLElement,
    eParentOfValue: {} as HTMLElement,
    api: {} as GridApi<string>,
    columnApi: {} as any,
    context: "",
    registerRowDragger: (rowDraggerElement: HTMLElement) => {},
  }

  return (
    <>
      <div className={classes.tableTaskBar}>
        {onSearchCallBack && (
          <div slot="actions-left" className={classes.searchBar}>
            <ENTextField
              hideLabel
              name="Search"
              placeholder="Search"
              handleOnChange={(e: any) => {
                const searchTerm = e?.target?.value?.trim() || ""
                if (searchTerm.length >= 3 || searchTerm.length <= 0) {
                  debouncedSearchHandler?.(searchTerm)
                }
              }}
              value=""
            />
          </div>
        )}
        <div className={classes.rightAligner}>
          {showActionBar && (
            <div className={classes.actionBar}>
              <div slot="actions-right">
                {numberOfRowsSelected && (
                  <ENTextPassage>
                    [{numberOfRowsSelected}] {label} Selected
                  </ENTextPassage>
                )}
              </div>
              {onActionCallBack && (
                <div slot="actions-right">
                  <ZtnaButton
                    buttonType="tertiary"
                    title={actionText}
                    contentColor={theme.palette.content.accentDefault}
                    onClick={onActionCallBack}
                  />
                </div>
              )}
            </div>
          )}

          {onRefreshCallBack && (
            <ZtnaTooltip
              title={refreshIntervalSeconds ? `Data refreshes every ${refreshIntervalSeconds} seconds` : undefined}
              placement="left"
              arrow
            >
              <div role="presentation" onClick={onRefreshCallBack}>
                <ENIconRefresh slot="end" size="md" />
              </div>
            </ZtnaTooltip>
          )}

          {showBulkActionMenu && (
            <div slot="actions-right">
              <DataGridPopover {...popOverParams} popOverList={onBulkActionPopover.popOverList} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DataGridNavigationBar
