import { Typography } from "@material-ui/core"
import { timeIntervalOptions } from "src/constants/dropdownsData"
import EnRadioGroup from "src/shared/components/FormComponents/EnRadioGroup"
import { ChartsTimeIntervalFilterType } from "src/utils"
import { useActivityLogsTimeFilterStyles } from "./ActivityLogsTimeFilter.styles"

interface ActivityLogsTimeFilterProps {
  onChange: (event: any) => void
  value: ChartsTimeIntervalFilterType
}

const ActivityLogsTimeFilter: React.FC<ActivityLogsTimeFilterProps> = ({ onChange, value }) => {
  const classes = useActivityLogsTimeFilterStyles()
  return (
    <div className={classes.root}>
      <Typography variant="body1" noWrap>
        Show Data of:
      </Typography>
      <EnRadioGroup value={value} onChange={onChange} name="" options={timeIntervalOptions} />
    </div>
  )
}

export default ActivityLogsTimeFilter
