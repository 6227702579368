import { compare, CompareOperator } from "compare-versions"
import platform from "platform"
import { DevicePostureOsAndBrowserType, DevicePostureType } from "src/services/api/swrHooks/useDevicePostures"
import { UAParser } from "ua-parser-js"

const defaultPostureCheck = {
  anyVersion: false,
  id: "",
  isEnabled: false,
  name: "",
  operator: "",
  version: "",
}

const mapOSName = (osName: string): string[] | string => {
  const osMapper: Record<string, string[]> = {
    Linux: [
      "Ubuntu",
      "Debian",
      "Fedora",
      "Red Hat",
      "SuSE",
      "Tizen",
      "CentOS",
      "FreeBSD",
      "OpenBSD",
      "Gentoo",
      "Cygwin",
      "webOS",
      "KaiOS",
    ],
  }
  return osMapper[osName] || osName
}

const isIpadOs = (ua: string) => {
  if (/iPad|iPod/.test(ua)) {
    return { os: "iPadOS", version: platform.os.version }
  } else if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /Macintosh/.test(ua)) {
    return { os: "iPadOS", version: ua.split("Version/")[1].split(" ")[0] }
  } else return {}
}

export const checkDevicePostureForOS = (
  serviceDevicePosture: DevicePostureType,
  isWindows11?: boolean,
): {
  devicePostureNotMatchingOS: DevicePostureOsAndBrowserType[]
} => {
  const parser = new UAParser(platform?.ua)
  const parsedInfo = parser.getOS()

  const systemInfo = {
    os: isIpadOs(platform.ua)?.os || mapOSName(parsedInfo.name || ""),
    osVersion: isWindows11 ? "11.0" : isIpadOs(platform.ua)?.version || parsedInfo.version || platform.version || "",
  }

  const { operatingSystemCheck, isOsEnabled } = serviceDevicePosture

  if (!isOsEnabled)
    return {
      devicePostureNotMatchingOS: [],
    }

  const OsMatchingDevice = operatingSystemCheck.find((operatingSystem) => {
    if (Array.isArray(systemInfo?.os)) {
      return systemInfo?.os.some((os) => operatingSystem.name.includes(os))
    } else {
      return operatingSystem.name.includes(systemInfo?.os)
    }
  })

  if (OsMatchingDevice && !OsMatchingDevice?.isEnabled)
    return {
      devicePostureNotMatchingOS: [defaultPostureCheck],
    }

  if (OsMatchingDevice) {
    const operatorValue = OsMatchingDevice.operator === "==" ? "=" : OsMatchingDevice.operator
    if (!OsMatchingDevice.anyVersion) {
      // check if system version detected is a valid number.if not logout.
      if (isNaN(Number(systemInfo?.osVersion)))
        return {
          devicePostureNotMatchingOS: [OsMatchingDevice],
        }

      if (!compare(systemInfo.osVersion, OsMatchingDevice.version || "", operatorValue as CompareOperator)) {
        return {
          devicePostureNotMatchingOS: [OsMatchingDevice],
        }
      }
    }
    return {
      devicePostureNotMatchingOS: [],
    }
  }

  return {
    devicePostureNotMatchingOS: [defaultPostureCheck],
  }
}

export const checkDevicePostureForBrowser = (
  serviceDevicePosture: DevicePostureType,
): {
  devicePostureNotMatchingBrowser: DevicePostureOsAndBrowserType[]
} => {
  const browserInfo = { browser: platform.name, browserVersion: platform.version }
  const { browserCheck, isBrowserEnabled } = serviceDevicePosture

  if (!isBrowserEnabled)
    return {
      devicePostureNotMatchingBrowser: [],
    }

  const browserMatchingCurrentBrowser = browserCheck.find((browser) => {
    return browserInfo?.browser?.includes(browser?.name)
  })

  if (browserMatchingCurrentBrowser && !browserMatchingCurrentBrowser?.isEnabled)
    return {
      devicePostureNotMatchingBrowser: [defaultPostureCheck],
    }

  if (browserMatchingCurrentBrowser) {
    const operatorValue = browserMatchingCurrentBrowser.operator === "==" ? "=" : browserMatchingCurrentBrowser.operator
    const browserVersionArrayfied = browserInfo.browserVersion.split(".")

    if (!browserMatchingCurrentBrowser.anyVersion) {
      if (
        !compare(
          browserVersionArrayfied.length <= 1
            ? browserInfo.browserVersion || ""
            : browserVersionArrayfied.slice(0, 1).join("."),
          browserMatchingCurrentBrowser.version.split(".").slice(0, 1).join(".") || "",
          operatorValue as CompareOperator,
        )
      ) {
        return {
          devicePostureNotMatchingBrowser: [browserMatchingCurrentBrowser],
        }
      }
    }
    return {
      devicePostureNotMatchingBrowser: [],
    }
  }
  return {
    devicePostureNotMatchingBrowser: [defaultPostureCheck],
  }
}
