import { Paper } from "@material-ui/core"
import { ICellRendererParams } from "ag-grid-community"
import clsx from "clsx"
import { ENButton } from "en-react/dist/src/components/Button"
import { ENDivider } from "en-react/dist/src/components/Divider"
import { ENList } from "en-react/dist/src/components/List"
import { ENMenu } from "en-react/dist/src/components/Menu"
import { ENMenuItem } from "en-react/dist/src/components/MenuItem"
import React, { useEffect, useRef, useState } from "react"
import ZtnaIcon from "src/shared/components/Icons/ZtnaIcon"
import { PopoverButtonType } from "src/shared/components/Popover"
import ZtnaTooltip from "src/shared/components/ZtnaTooltip"
import { useDataGridPopOverStyles } from "./DataGridPopOver.styles"

const DataGridPopover = <RowDataType extends any>(
  props: ICellRendererParams<RowDataType> & { popOverList: PopoverButtonType[]; isExpandedView?: boolean },
) => {
  const popoverRef = useRef<HTMLInputElement>(null)
  const [popoverMenuPosition, setPopoverMenuPosition] = useState({ top: 500, left: 900 })

  const classes = useDataGridPopOverStyles({ top: popoverMenuPosition.top, left: popoverMenuPosition.left })

  const hasNoItems = props.popOverList.every(
    (item) =>
      (item.hide && !!item.hide(props.data)) || (item.show && !item.show(props.data)) || item.variant === "separator",
  )
  const calculatePopoverMenuPosition = () => {
    const rect = popoverRef?.current?.getBoundingClientRect()
    const top = rect?.top ? rect.top + window.scrollY : 0
    const left = rect?.left ? rect.left - 180 : 0
    setPopoverMenuPosition({ top, left })
  }
  useEffect(() => {
    if (props.isExpandedView) {
      calculatePopoverMenuPosition()
      if (popoverRef.current) {
        popoverRef.current.addEventListener("click", calculatePopoverMenuPosition)
      }
      window.addEventListener("resize", calculatePopoverMenuPosition)
      return () => {
        if (popoverRef.current) {
          popoverRef.current.removeEventListener("click", calculatePopoverMenuPosition)
        }
        window.removeEventListener("resize", calculatePopoverMenuPosition)
      }
    }
  }, [])

  const PopOverChildComponent = (
    <React.Fragment key=".0">
      {props.popOverList.map(
        ({
          name,
          title,
          callback,
          disabled,
          variant = "normal",
          show,
          tooltipText,
          hide,
          disabledTooltipText,
          disabledTooltipTextPlacement,
        }) => {
          if (variant === "separator") {
            return <ENDivider />
          }
          return (
            <ZtnaTooltip
              title={
                (disabled?.(props.data)
                  ? typeof disabledTooltipText === "function"
                    ? disabledTooltipText(props.data)
                    : disabledTooltipText
                  : tooltipText) || ""
              }
              arrow
              key={`PopoverTooltip-${name}`}
              placement={disabledTooltipTextPlacement || "bottom"}
              enableTextWrap
            >
              <ENMenuItem
                key={name}
                isDisabled={(disabled && disabled(props.data)) || false}
                onClick={() => {
                  callback?.(name, props.data)
                }}
                style={(hide && !!hide(props.data)) || (show && !show(props.data)) ? { display: "none" } : {}}
              >
                <div className={classes.popOverMenuItem}>
                  <span>{typeof title === "function" ? title(props.data) : title}</span>
                </div>
              </ENMenuItem>
            </ZtnaTooltip>
          )
        },
      )}
    </React.Fragment>
  )

  return (
    <ENMenu
      position={
        props?.node?.rowIndex &&
        props?.node?.rowIndex - props?.api.paginationGetCurrentPage() * props?.api.paginationGetPageSize() >= 6
          ? "top-left"
          : "left"
      }
    >
      <React.Fragment key=".0">
        <div className={classes.actionButton} slot="trigger" ref={popoverRef}>
          <ZtnaTooltip
            title={hasNoItems ? "No action can be taken with the current state of the resource" : ""}
            placement="left"
          >
            <ENButton variant="tertiary">
              <React.Fragment key=".0">
                <ZtnaIcon name="actionsDataGrid" />
              </React.Fragment>
            </ENButton>
          </ZtnaTooltip>
        </div>

        {!hasNoItems ? (
          <div
            className={clsx({
              [classes.listContainerExpanded]: props.isExpandedView,
              [classes.listContainer]: !props.isExpandedView,
            })}
          >
            <ENList>
              {props.isExpandedView ? (
                <Paper className={classes.paperContainer}>{PopOverChildComponent}</Paper>
              ) : (
                <>{PopOverChildComponent}</>
              )}
            </ENList>
          </div>
        ) : null}
      </React.Fragment>
    </ENMenu>
  )
}

export default DataGridPopover
