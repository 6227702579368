import { Chip } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import clsx from "clsx"
import { ENHeading } from "en-react/dist/src/components/Heading"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { RootState } from "src/store"
import { BreadcrumbsType } from "src/store/ui/types"
import { useBreadcrumbsStyles } from "./Breadcrumbs.styles"
const AppBreadcrumbs: React.FC<BreadcrumbsType> = ({ data, badge, secondaryBadge, secondaryText }) => {
  const classes = useBreadcrumbsStyles()
  const { title: badgeTitle, type: badgeType, isLoading: badgeLoading } = badge || {}
  const {
    title: secondaryBadgeTitle,
    type: secondaryBadgeType,
    isLoading: secondaryBadgeLoading,
  } = secondaryBadge || {}

  const {
    routeInfo: { currentRoute },
    upgradeNotificationsData,
    sideNav: { isOpened },
  } = useSelector((state: RootState) => state.ui)

  const showTabsView = currentRoute?.tabs?.length
  const showUpgradeConnectorBanner = !!upgradeNotificationsData?.connectorUpgradeData?.status
  const showUpgradeRelayBanner =
    upgradeNotificationsData?.relayNodeUpgradeData?.status &&
    upgradeNotificationsData?.relayNodeUpgradeData?.status !== "Upgrade In-Progress"
  const showBanners = showUpgradeConnectorBanner || showUpgradeRelayBanner

  return (
    <>
      {(data.length || badge) && (
        <div className={clsx(classes.root, { [classes.navMargin]: !showTabsView && !showBanners && !isOpened })}>
          {data.map(({ title, url, callback }, idx) => {
            const isActive = data.length - 1 === idx
            return (
              <div key={`Breadcrumbs-${title}-${idx}`} className={classes.nodeRoot}>
                {callback ? (
                  <ENHeading variant="md">
                    <span className={clsx(classes.link, { [classes.active]: isActive })} onClick={callback}>
                      {title}
                    </span>
                  </ENHeading>
                ) : url ? (
                  <ENHeading variant="md">
                    <Link className={clsx(classes.link, { [classes.active]: isActive })} to={url}>
                      {title}
                    </Link>
                  </ENHeading>
                ) : (
                  <ENHeading variant="md">{title}</ENHeading>
                )}

                {!isActive && (
                  <div data-testid="arrow-icon" className={classes.separator}>
                    /
                  </div>
                )}
              </div>
            )
          })}
          {badgeTitle && (
            <div className={classes.badgeWrapper}>
              {badgeLoading ? (
                <Skeleton width={90} />
              ) : (
                <Chip
                  className={clsx(classes.badge, {
                    [classes.success]: badgeType === "success",
                    [classes.danger]: badgeType === "danger",
                  })}
                  label={badgeTitle}
                />
              )}
            </div>
          )}
          {secondaryBadgeTitle && (
            <div className={classes.badgeWrapper}>
              {secondaryBadgeLoading ? (
                <Skeleton width={90} />
              ) : (
                <Chip
                  className={clsx(classes.badge, {
                    [classes.success]: secondaryBadgeType === "success",
                    [classes.danger]: secondaryBadgeType === "danger",
                  })}
                  label={secondaryBadgeTitle}
                />
              )}
            </div>
          )}
          {secondaryText && <span className={classes.secondaryText}>{secondaryText}</span>}
        </div>
      )}
    </>
  )
}

export default AppBreadcrumbs
